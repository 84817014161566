import { Routes, Route } from 'react-router-dom'

import 'assets/styles/main.scss'
import './App.scss'

import Home from 'pages/Home.js'
import PageNotFound from 'pages/PageNotFound.js'
import ThankYou from 'pages/ThankYou.js'
import Header from 'components/Header/Header.js'
import Cookie from 'components/Cookie/Cookie.js'

function App() {
  return (
    <div className="app">
      <div className="app-container">
        <Header />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </div>
        <Cookie />
      </div>
    </div>
  )
}

export default App
