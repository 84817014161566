const DATA = [
  {
    id: 1,
    image: 'https://media4.giphy.com/media/Vp3ftHKvKpASA/giphy.gif',
    title: 'Frontend fejlesztő',
    description: '#Vue #React #Javascript #HTML5 #SCSS #Bootstrap'
  },
  {
    id: 2,
    image: 'https://media1.giphy.com/media/yQtlzCqB77hvy/giphy.gif',
    title: 'Backend fejlesztő',
    description: '#PHP #Laravel #RESTAPI #MySQL'
  },
  {
    id: 3,
    image: 'https://media0.giphy.com/media/GSq7lRoDLxjOg/giphy.gif',
    title: 'UI designer',
    description: '#Figma #UX #Sketch #Photoshop #Gif'
  },
  {
    id: 4,
    image: 'https://media2.giphy.com/media/NlX5jQVU7EKwo/giphy.gif',
    title: 'Online marketing szakértő',
    description: '#Google ADS #Facebook #TikTok #Instagram #GoogleBusiness'
  },
  {
    id: 5,
    image: 'https://media3.giphy.com/media/GNEoBlVDJsOe4/giphy.gif',
    title: 'Account manager',
    description: '#kommunikáció #projektmenedszment #önállóság #stratégia #határozottság'
  }
]

export default DATA
