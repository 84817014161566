import { useEffect } from 'react'
import Hammer from 'hammerjs'

import './Cards.scss'
import Card from './Card.js'

export default function Cards({ cards, handleLike, currentCard, setCurrentCard }) {
  function initCards() {
    const $cards = document.querySelectorAll('.card')
    const $newCards = document.querySelectorAll('.card:not(.removed)')
    const $cardContainer = document.querySelector('.cards')

    $newCards.forEach(function (card, index) {
      card.style.zIndex = $cards.length - index
      card.style.transform = 'scale(' + (20 - index) / 20 + ') translateY(-' + 20 * index + 'px)'
    })

    $cardContainer.classList.add('loaded')
  }

  useEffect(() => {
    const $cards = document.querySelectorAll('.card')
    const $cardContainer = document.querySelector('.cards')

    initCards()

    $cards.forEach((card, index) => {
      const hammertime = new Hammer(card)

      hammertime.on('pan', function (event) {
        card.classList.add('moving')
      })

      hammertime.on('pan', function (event) {
        if (!event.target.classList.contains('card')) return
        if (event.deltaX === 0) return
        if (event.center.x === 0 && event.center.y === 0) return

        $cardContainer.classList.toggle('like', event.deltaX > 0)
        $cardContainer.classList.toggle('dislike', event.deltaX < 0)

        const xMulti = event.deltaX * 0.03
        const yMulti = event.deltaY / 80
        const rotate = xMulti * yMulti

        event.target.style.transform =
          'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)'
      })

      hammertime.on('panend', function (event) {
        card.classList.remove('moving')
        $cardContainer.classList.remove('like')
        $cardContainer.classList.remove('dislike')

        const moveOutWidth = document.body.clientWidth
        const keep = Math.abs(event.deltaX) < 30 || Math.abs(event.velocityX) < 0.5

        card.classList.toggle('removed', !keep)

        if (keep) {
          event.target.style.transform = ''
        } else {
          const endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth)
          const toX = event.deltaX > 0 ? endX : -endX
          const endY = Math.abs(event.velocityY) * moveOutWidth
          const toY = event.deltaY > 0 ? endY : -endY
          const xMulti = event.deltaX * 0.03
          const yMulti = event.deltaY / 80
          const rotate = xMulti * yMulti

          if (event.target.classList.contains('removed')) {
            const nextCard = $cards[index + 1]
            const isLike = event.deltaX > 0

            if (isLike) {
              handleLike(cards[index])
            }

            if (nextCard) {
              nextCard.classList.add('is-active')
            }

            setCurrentCard(index + 1)
          }

          event.target.style.transform = `translate('${toX}px,${toY + event.deltaY}px) rotate(${rotate}deg')`
          initCards()
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (isLike, event) => {
    const $cards = document.querySelectorAll('.card')
    const currentCard = event.target.closest('.card')
    const moveOutWidth = document.body.clientWidth * 1.5
    const SWIPE_ANIMATION_TIME = 300

    if (!$cards.length) return false

    if (isLike) {
      currentCard.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)'
    } else {
      currentCard.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)'
    }

    setTimeout(() => {
      currentCard.classList.add('removed')

      const index = Array.from($cards).indexOf(currentCard)
      const nextCard = $cards[index + 1]

      if (nextCard) {
        nextCard.classList.add('is-active')
      }

      if (isLike) {
        handleLike(cards[index])
      }

      setCurrentCard(index + 1)
      initCards()
    }, SWIPE_ANIMATION_TIME)
  }

  return (
    <div className="cards-wrapper">
      <div className="cards-counter text-center">
        <small className="color-white">
          <strong>
            {currentCard}/{cards.length}
          </strong>
        </small>
      </div>
      <h1 className="mt-10px text-center color-white">El tudod képzelni magad, mint</h1>
      <div className="cards">
        {cards.map(card => (
          <Card
            image={card.image}
            title={card.title}
            description={card.description}
            key={card.id}
            onClick={(isLike, event) => handleClick(isLike, event)}
          />
        ))}
      </div>
    </div>
  )
}
