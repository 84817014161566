import closeIcon from 'assets/images/close.svg'
import heartIcon from 'assets/images/heart.svg'

export default function Card({ image, title, description, onClick }) {
  return (
    <div className="card">
      <div className="card__image" style={{ backgroundImage: `url(${image})` }}>
        <h2 className="card__title color-white">{title}</h2>
      </div>
      <p className="card__description text-center mt-20px">{description}</p>
      <div className="card__actions mt-20px d-flex justify-content-center align-items-center">
        <button className="btn-icon btn-danger">
          <img onClick={event => onClick(false, event)} src={closeIcon} alt="close icon" className="icon" />
        </button>
        <button className="btn-icon btn-primary">
          <img onClick={event => onClick(true, event)} src={heartIcon} alt="heart icon" className="icon" />
        </button>
      </div>
    </div>
  )
}
