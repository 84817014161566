import MailchimpSubscribe from 'react-mailchimp-subscribe'

import upIcon from '../../assets/images/up.svg'
import './ApplyForm.scss'
import ThankYou from 'pages/ThankYou'

function CustomForm({ status, message, onValidated, likes }) {
  let name, email, positions

  const handleSubmit = () => {
    return (
      email &&
      email.value.indexOf('@') > -1 &&
      name &&
      onValidated({
        EMAIL: email.value,
        NAME: name.value,
        POSITION: positions.value
      })
    )
  }

  return (
    <div>
      {status !== 'success' && (
        <div className="apply-form__content">
          <h1 className="mb-15px text-center">El tudom képzelni magam, mint</h1>
          <small>
            <strong>Poziciók</strong>
          </small>
          <ul className="list mt-10px mb-20px">
            {likes.map(item => (
              <li key={item.id}>{item.title}</li>
            ))}
          </ul>
          <div className="form">
            <div className="form-group">
              <label htmlFor="fullName">Név</label>
              <input
                className="form-control"
                ref={node => (name = node)}
                type="text"
                id="fullName"
                name="NAME"
                placeholder="Minta János"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                className="form-control"
                ref={node => (email = node)}
                type="email"
                id="email"
                name="EMAIL"
                placeholder="mintajanos@gmail.com"
                required
              />
            </div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input
                ref={node => (positions = node)}
                type="text"
                id="position"
                name="POSITION"
                value={likes.map(item => item.title).join(',')}
                tabIndex="-1"
                readOnly
              />
            </div>
            <div className="mb-20px d-flex justify-content-center">
              <button onClick={handleSubmit} className="btn btn-primary mt-10px">
                Jelentkezem
              </button>
              {status === 'sending' && <small>Küldés...</small>}
              {status === 'error' && <small style={{ color: 'red' }}>{message}</small>}
            </div>
          </div>
        </div>
      )}
      {status === 'success' && <ThankYou />}
    </div>
  )
}

export default function ApplyForm({ likes, isOpen, toggleForm }) {
  const formActionURL =
    'https://y-collective.us14.list-manage.com/subscribe/post?u=f258a00aba7ddeb58b7dbdd15&id=c9158e9fb4&f_id=00de8ae0f0'

  return (
    <div className="apply-form-wrapper">
      {isOpen && <div className="page-overlay"></div>}
      <div className={isOpen ? 'apply-form is-open' : 'apply-form'}>
        <div className="apply-form__container">
          <div onClick={toggleForm} className="apply-form__header">
            <div className="apply-form__trigger">
              <img src={upIcon} alt="up icon" />
            </div>
            <h3 className="apply-form__title">
              <span className="apply-form__counter mr-10px">{likes.length}</span>
              <span>Jelentkezem</span>
            </h3>
          </div>
          {likes.length > 0 && (
            <MailchimpSubscribe
              url={formActionURL}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                  likes={likes}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}
