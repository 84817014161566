import cowUfo from '../assets/images/ufo-2.svg'

export default function ThankYou() {
  return (
    <div className="thank-you-page">
      <div className="text-center">
        <img className="mt-10px" src={cowUfo} alt="cows sitting in an ufo" />
        <h1 className="mt-30px">Köszönjük jelentkezésed! 🙌</h1>
        <p className="mt-10px px-30px">
          Munkatársunk hamarosan felveszi veled a kapcsolatot a megadott email címeden. Szép napot! ☀️
          <br />
          <br />
          Üdv,
          <br />
          YCO csapata
        </p>
      </div>
    </div>
  )
}
