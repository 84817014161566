import { useState } from 'react'
import Cookies from 'js-cookie'

import CookieIcon from '../../assets/images/cookie.svg'
import './Cookie.scss'

export default function Cookie() {
  const COOKIE_KEY = 'ycoworker_cookie_consent'
  const [isOpen, setIsOpen] = useState(true)

  const hasAcceptedCookie = () => Cookies.get(COOKIE_KEY)

  const setCookie = () => {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)
    Cookies.set(COOKIE_KEY, 1, { expires: expiresDate })
    setIsOpen(false)
  }

  if (isOpen && !hasAcceptedCookie()) {
    return (
      <div className="cookie-wrapper">
        <div className="page-overlay"></div>
        <div className="cookie">
          <h3 className="d-flex align-items-center mb-15px">
            <img src={CookieIcon} alt="cookie icon" className="mr-10px" />
            <span>Ez a weboldal sütiket használ</span>
          </h3>
          <small>
            Weboldalunk a zavartalan működés és a felhasználói élmény javítása érdekében különböző típusú sütiket
            (cookie) használ.
          </small>
          <div className="d-flex align-items-center mt-30px">
            <button onClick={setCookie} className="btn btn-primary btn-small mr-20px">
              Elfogadom
            </button>
            <a href="https://y-shift.com/adatkezeles/" target="_blank" rel="noreferrer" className="btn-text">
              Adatvédelmi beállítások
            </a>
          </div>
        </div>
      </div>
    )
  }
}
