import { useState, useEffect } from 'react'

import cardsData from 'constants/jobs.js'
import ApplyForm from 'components/ApplyForm/ApplyForm.js'
import Cards from 'components/Cards/Cards.js'

export default function Home() {
  const [currentCard, setCurrentCard] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [likes, setLikes] = useState([])

  useEffect(() => {
    if (currentCard === cardsData.length) {
      setIsOpen(true)
    }
  }, [currentCard])

  const handleLike = item => {
    setLikes(likes => [...likes, item])
  }

  const toggleForm = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="home-page">
      <ApplyForm likes={likes} isOpen={isOpen} toggleForm={toggleForm} />
      <div className="home-page-background"></div>
      <div className="home-page-content">
        <Cards cards={cardsData} handleLike={handleLike} currentCard={currentCard} setCurrentCard={setCurrentCard} />
      </div>
    </div>
  )
}
