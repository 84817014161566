import logo from '../../assets/images/logo.svg'

export default function Header() {
  return (
    <header className="header pt-20px text-center">
      <a href="/">
        <img src={logo} alt="YCO logo" />
      </a>
    </header>
  )
}
